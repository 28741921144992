import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
      <section className='relative bg-white'>
          <nav className=''>
            <div className='container mx-auto px-4'>
              <div className='flex items-center h-20'>
                <a className='inline-block' href='/'>
                  <img
                    className='h-7'
                    src='images/logo.svg'
                    alt=''
                  />
                </a>
                <button className='lg:hidden navbar-burger py-1 ml-auto'>
                  <svg
                    width={44}
                    height={16}
                    viewBox='0 0 44 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect width={44} height={2} rx={1} fill='black' />
                    <rect y={14} width={44} height={2} rx={1} fill='black' />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-auto mr-12 items-center'>
                  <a className='inline-block hover:underline mr-10' href='/'>
                    Homepage
                  </a>
                  <a className='inline-block hover:underline' href='https://oculoplastics.medfy.net'>
                    Courses
                  </a>
                </div>
                <a
                  className='group hidden lg:inline-flex py-4 px-8 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-black transition duration-200'
                  href='https://oculoplastics.medfy.net'
                  target='_blank'
                >
                  <span className='mr-2'>Sign Up</span>
                  <span className='group-hover:rotate-45 transform transition duration-100'>
                    <svg
                      width={10}
                      height={10}
                      viewBox='0 0 10 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9 1L1 9'
                        stroke='currentColor'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9 8.33571V1H1.66429'
                        stroke='currentColor'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </nav>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-50' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='/'>
                  <img
                    className='h-6'
                    src='images/logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='/'>
                      Homepage
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='/'>
                      Courses
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='block mb-3 px-4 py-4 text-center font-medium text-black hover:text-white border border-black hover:bg-black rounded-full transition duration-200'
                  href='https://oculoplastics.medfy.net'
                >
                  Sign Up
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative bg-coolGray-50 overflow-hidden pt-12'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-6 lg:mb-0'>
                <div className='flex flex-col h-full'>
                  <img
                    className='block w-full mb-6 h-32 object-cover rounded-2xl'
                    src='https://images.unsplash.com/photo-1539768942893-daf53e448371?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxfHxlZ3lwdHxlbnwwfHx8fDE2OTc1ODMwNjR8MA&ixlib=rb-4.0.3&q=85&w=1920'
                    alt=''
                  />
                  <div className='py-10 px-10 h-full bg-white rounded-2xl'>
                    <h1 className='max-w-xs md:max-w-md font-heading text-5xl sm:text-7xl mb-4 tracking-tighter'>
                      An unique adventure through Egypt
                    </h1>
                    <div className='max-w-sm ml-auto text-right pt-6'>
                      <span className='block mb-1 text-xs'>April 8-17</span>
                      <span className='block mb-3 text-xs'>2024</span>
                      <p>
                        <span className='block'>
                          The Oculoplastics Academy presents an adventure through
                          Egypt, combining international masters and
                          historic locations in one event.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div className='relative h-full'>
                  <img
                    className='block h-full w-full object-cover rounded-2xl'
                    src='https://images.unsplash.com/photo-1572252009286-268acec5ca0a?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwzfHxlZ3lwdHxlbnwwfHx8fDE2OTc1ODMwNjR8MA&ixlib=rb-4.0.3&q=85&w=1920'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-coolGray-50 pt-12 md:pt-24'>
          <div className='container mx-auto px-4'>
            <div className='max-w-xl mx-auto lg:max-w-none'>
              <h1 className='font-heading text-6xl sm:text-8xl md:text-10xl tracking-tighter mb-8 md:mb-16'>
                What the experience provides
              </h1>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                  <div className='max-w-xl'>
                    <button className='group block text-left w-full py-10 px-0 border-b border-coolGray-300'>
                      <div className='flex w-full justify-between items-center'>
                        <h5 className='text-3xl sm:text-4xl'>
                          High-level education
                        </h5>
                      </div>
                      <div className='max-w-lg mt-8'>
                        <p className='text-xl'>
                          Get access to renowned experts in the field of
                          oculoplasty means you will receive education from
                          high quality. These authorities can share
                          valuable insights and advanced techniques.
                        </p>
                      </div>
                    </button>
                    <button className='block text-left w-full pt-10'>
                      <div className='flex w-full justify-between items-center'>
                        <h5 className='text-3xl sm:text-4xl'>Networking</h5>
                      </div>
                      <div className='max-w-lg mt-8'>
                        <p className='text-xl'>
                          We bring together professionals from all over the world. That
                          provides an exceptional opportunity to create
                          a global network of contacts, which can be useful for
                          future collaborations, knowledge sharing
                          and career opportunities.
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <img
                    className='block mx-auto lg:mr-0 rounded-xl mt-12'
                    src='https://images.unsplash.com/photo-1502640403806-cf2ac7a5d37a?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxMXx8ZWd5cHR8ZW58MHx8fHwxNjk3NTgzMDY0fDA&ixlib=rb-4.0.3&q=85&w=1920'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-coolGray-50'>
          <div className='container mx-auto px-4'>
            <div className='max-w-md mx-auto lg:max-w-none'>
              <div className='flex flex-wrap -mx-3'>
                <div className='w-full lg:w-1/3 px-3 mb-6 lg:mb-0'>
                  <div className='relative group block h-full w-full px-9 py-14 border rounded-4xl'>
                    <span className='hidden group-hover:block absolute top-0 right-0 mt-16 mr-8'></span>
                    <div>
                      <div className='max-w-xs pr-10'>
                        <h5 className='text-3xl xs:text-4xl mb-6'>
                          Cultural experience
                        </h5>
                      </div>
                      <p className=''>
                        In addition to medical education, you will have the chance to explore
                        Egypt, a place rich in history and culture. Visit
                        historic sites such as the pyramids and the Valley of the Kings,
                        it can be an enriching experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-3 mb-6 lg:mb-0'>
                  <div className='relative group block h-full w-full px-9 py-14 border rounded-4xl'>
                    <span className='hidden group-hover:block absolute top-0 right-0 mt-16 mr-8'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      />
                    </span>
                    <div>
                      <div className='max-w-xs pr-10'>
                        <h5 className='text-3xl xs:text-4xl mb-6'>
                          Case studies
                        </h5>
                      </div>
                      <p className=''>
                        Presenting clinical cases in an environment where others
                        experts can provide feedback is one way
                        amazing way to improve your skills. You can also
                        learn from cases presented by others
                        participants.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-3'>
                  <div className='relative group block h-full w-full px-9 py-14 border rounded-4xl'>
                    <span className='hidden group-hover:block absolute top-0 right-0 mt-16 mr-8'></span>
                    <div>
                      <div className='max-w-xs pr-10'>
                        <h5 className='text-3xl xs:text-4xl mb-6'>
                          Expand your horizons
                        </h5>
                      </div>
                      <p className=''>
                        Traveling to a different location provides a
                        broader perspective and the opportunity to experience
                        a completely different culture. This can
                        enrich your personal and professional life.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-36 bg-black overflow-hidden'>
          <img
            className='absolute top-0 left-0 w-full h-full object-cover'
            src='assets/test4.jpg'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='lg:max-w-3xl mx-auto text-center'>
              <h1 className='font-heading text-5xl sm:text-8xl xl:text-10xl text-white tracking-tighter mb-14'>
                <span>Discover Egypt like</span>
                <span />
                <span className='inline-block border-b-4 border-white'>
                  never seen before
                </span>
              </h1>
              <a
                className='group inline-flex mb-3 sm:mb-0 sm:mr-4 py-5 px-8 text-black hover:text-white leading-none items-center justify-center bg-white hover:bg-black rounded-full transition duration-150'
                href='https://5uvxornmh8q.typeform.com/egiptyadventure'
              >
                <span className='mr-2.5'>I want to guarantee my spot</span>
                <span className='group-hover:rotate-45 transform transition duration-75'>
                  <svg
                    width={10}
                    height={11}
                    viewBox='0 0 10 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9 1.5L1 9.5'
                      stroke='currentColor'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9 8.83571V1.5H1.66429'
                      stroke='currentColor'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

