import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import EgyptPage from './pages/egypt.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/egypt" element={<EgyptPage />} />
    </Routes>
  );
}

export default App;
