import React, { useEffect, useState, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

const sections = document.querySelectorAll('.hidden-section');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.remove('hidden-section');
      entry.target.classList.add('visible-section');
    }
  });
});

sections.forEach((section) => {
  observer.observe(section);
});

export default function Index() {
  useEffect(() => {
    const sections = document.querySelectorAll('.hidden-section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('hidden-section');
          entry.target.classList.add('visible-section');
        }
      });
    }, { rootMargin: '0px 0px -10% 0px' });

    sections.forEach((section) => {
      observer.observe(section);
    });

    // Optional: Cleanup when component unmounts
    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sliderRef = useRef(null);

  const scrollSlider = (direction) => {
    const width = sliderRef.current.clientWidth;
    if (direction === 'right') {
      sliderRef.current.scrollBy({ left: width / 2, behavior: 'smooth' });
    } else {
      sliderRef.current.scrollBy({ left: -width / 2, behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}>
          <script src="//cdn.conveythis.com/javascript/conveythis-initializer.js"></script>
            <script>
              {`
                document.addEventListener("DOMContentLoaded", function(e) {
                  ConveyThis_Initializer.init({
                    api_key: "pub_9d22798df9a264d13193be58964c4094"
                  });
                });
              `}
            </script>
        </Helmet>
      </HelmetProvider>
      <>
        <section className='relative bg-coolGray-50'>
          <nav className=''>
            <div className='container mx-auto px-4'>
              <div className='flex items-center h-20'>
                <a className='inline-block' href='/'>
                  <img
                    className='h-7'
                    src='images/logo.svg'
                    alt=''
                  />
                </a>
                <button 
                className='lg:hidden navbar-burger py-1 ml-auto' 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <svg
                    width={44}
                    height={16}
                    viewBox='0 0 44 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect width={44} height={2} rx={1} fill='black' />
                    <rect y={14} width={44} height={2} rx={1} fill='black' />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-auto mr-12 items-center'>
                  <a className='inline-block hover:underline mr-10' href='/'>
                    Homepage
                  </a>
                  <a className='inline-block hover:underline' href='https://oculoplastics.medfy.net'>
                    Courses
                  </a>
                </div>
                <a
                  className='group hidden lg:inline-flex py-4 px-8 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-black transition duration-200'
                  href='https://oculoplastics.medfy.net'
                  target='_blank'
                >
                  <span className='mr-2'>Sign up</span>
                  <span className='group-hover:rotate-45 transform transition duration-100'>
                    <svg
                      width={10}
                      height={10}
                      viewBox='0 0 10 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9 1L1 9'
                        stroke='currentColor'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9 8.33571V1H1.66429'
                        stroke='currentColor'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </nav>
          <div className={isMenuOpen ? 'navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'}
          >
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-50' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-6'
                    src='images/logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#' onClick={() => setIsMenuOpen(false)}>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='/'>
                      Homepage
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='https://oculoplastics.medfy.net'>
                      Courses
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='block mb-3 px-4 py-4 text-center font-medium text-black hover:text-white border border-black hover:bg-black rounded-full transition duration-200'
                  href='#'
                >
                  Sign up
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-36 md:py-48 bg-black overflow-hidden'>
          <img
            className='absolute top-0 left-0 w-full h-full object-cover'
            src='assets/bg.gif'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='lg:max-w-3xl mx-auto text-center'>
              <h1 className='font-heading text-5xl sm:text-8xl xl:text-10xl text-white tracking-tighter mb-14'>
                <span>Welcome to the future of Oculoplastics</span>
              </h1>
              <div className='max-w-xl mx-auto mb-10'>
                <p className='text-lg text-gray-200 px-4 md:px-0'>
                  We are a global group that innovates to ensure the delivery of
                  highest quality in Medical Education.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-blue-900 px-4 md:px-0'>
          <div className='hidden-section container mx-auto px-4'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 xl:w-7/12 px-4 md:mb-0 mb-8'>
                <div className='max-w-xl mx-auto lg:mx-0'>
                  <div className='max-w-sm xl:max-w-md mb-12'>
                    <h1 className='font-heading tracking-tighter text-white mb-6 text-5xl md:text-8xl lg:text-9xl'>
                      About us
                    </h1>
                    <p className='max-w-sm text-xl text-blue-100'>
                      The Oculoplastics Academy offers courses for
                      medical professionals in the areas of plastic surgery,
                      ophthalmology, otolaryngology, dermatology and
                      aesthetic medicine.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className='w-full lg:w-1/2 xl:w-5/12 px-4'>
                <div className='max-w-xl lg:max-w-none mx-auto'>
                  <img
                    className='block w-full h-88 rounded-4xl object-cover'
                    src='images/picture-left-text-right-primary-content-image-1.jpeg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="py-12 md:py-20 lg:py-24" >
          <div class="container hidden-section mx-auto px-4">
            <div class="lg:max-w-3xl mx-auto text-center">

              <h1 class="font-heading text-5xl sm:text-7xl md:text-9xl tracking-tighter mb-12">Global spotlight launches</h1>
              <div class="container mx-auto px-4">

                <div class="relative pb-10 pt-20 lg:pt-10 px-8 sm:px-12 xl:px-32 h-96">
                  <iframe class="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/nyuuqfnU7TE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

              </div>
              <a class="group mt-8 inline-flex py-5 px-8 font-medium text-white hover:text-black leading-none items-center justify-center bg-blue-500 hover:bg-white rounded-full transition duration-150" href="/egypt">
                <span class="mr-2.5">Learn more</span>
                <span class="group-hover:rotate-45 transform transition duration-75">
                  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1.5L1 9.5" stroke="currentColor" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 8.83571V1.5H1.66429" stroke="currentColor" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
              </a>
            </div>
          </div>
        </section>
        <section class="py-12 md:py-24 px-4 bg-coolGray-50">
          <div class="hidden-section container mx-auto px-4">
            <div class="flex flex-wrap -mx-4 mb-12 md:mb-20">
              <div class="w-full">
                <h1 class="font-heading tracking-tighter text-center text-5xl md:text-8xl lg:text-9xl">Upcoming Global Events</h1>
              </div>
            </div>
            <div class="pb-16 mb-16 border-b border-gray-200">
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/3 p-8 mb-10 md:mb-0">
                  <a class="group block" href="https://oculoplasticsacademy.com/injetaveis-pantanal/">
                    <div class="relative mb-6 rounded-4xl overflow-hidden">
                      <img class="block w-full h-auto object-cover" src="assets/pantanal.jpeg" alt=""/>
                      <div class="absolute left-0 top-0 h-full w-full group-hover:bg-opacity-10 group-hover:bg-black transition duration-200"></div>
                    </div>
                    <div class="max-w-xs md:max-w-md xl:max-w-lg">
                      <h4 class="max-w-md text-3xl sm:text-4xl tracking-tight mb-6">Injectables Pantanal</h4>
                      <p class="text-lg mb-8">Learn the main facial rejuvenation techniques using injectables in a practical, effective and completely safe way.</p>
                    </div>
                  </a>
                </div>

                <div class="w-full md:w-1/3 p-8 mb-10 md:mb-0">
                  <a class="group block" href="https://oculoplasticsacademy.com/escola-de-blefaroplastia/">
                    <div class="relative mb-6 rounded-4xl overflow-hidden">
                      <img class="block w-full h-auto object-cover" src="assets/blefaro.jpeg" alt=""/>
                      <div class="absolute left-0 top-0 h-full w-full group-hover:bg-opacity-10 group-hover:bg-black transition duration-200"></div>
                    </div>
                    <div class="max-w-xs md:max-w-md xl:max-w-lg">
                      <h4 class="max-w-md text-3xl sm:text-4xl tracking-tight mb-6">Blepharoplasty School </h4>
                      <p class="text-lg mb-8">We invite you to participate in the Blepharoplasty School in São Paulo, an event that brings together experts in the field in one place.</p>
                    </div>
                  </a>
                </div>

                <div class="w-full md:w-1/3 p-8 mb-10 md:mb-0">
                  <a class="group block" href="https://oculoplasticsacademy.com/palpebras-br-hands-on/">
                    <div class="relative mb-6 rounded-4xl overflow-hidden">
                      <img class="block w-full h-auto object-cover" src="assets/palpebras.jpeg" alt=""/>
                      <div class="absolute left-0 top-0 h-full w-full group-hover:bg-opacity-10 group-hover:bg-black transition duration-200"></div>
                    </div>
                    <div class="max-w-xs md:max-w-md xl:max-w-lg">
                      <h4 class="max-w-md text-3xl sm:text-4xl tracking-tight mb-6">Eyelids.BR 2024</h4>
                      <p class="text-lg mb-8">The first and most complete International course on Eyelids - Hands On on fresh anatomical pieces.</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
          <section className='relative py-5 bg-coolGray-50'>
          <div className='hidden-section container mx-auto px-4'>
            <div className='px-8 md:px-12 bg-white rounded-4xl overflow-hidden'>
              <div className='relative max-w-5xl mx-auto'>
                <img
                  className='hidden md:block absolute bottom-0 right-0 md:-mr-24 sm:w-8/12'
                  src='assets/phone-right.png'
                  alt=''
                />
                <div className='relative max-w-sm xl:max-w-lg mx-auto md:mx-0 pt-20 pb-12 md:py-20'>
                  <span className='inline-block text-sm text-black leading-none mb-2'>
                    In partnership with Medfy™
                  </span>
                  <h2 className='font-heading text-4xl xs:text-5xl xl:text-7xl tracking-tight mb-12'>
                    The new Oculoplastics platform
                  </h2>
                  <div className='flex max-w-sm'>
                    <a
                      className='group inline-flex py-5 px-8 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-black transition duration-200 w-full md:w-auto'
                      href='https://oculoplastics.medfy.net'
                      target='_blank'
                    >
                      <span className='mr-2'>Access the new plataform</span>
                      <span className='group-hover:rotate-45 transform transition duration-100'>
                        <svg
                          width={10}
                          height={10}
                          viewBox='0 0 10 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9 1L1 9'
                            stroke='currentColor'
                            strokeWidth='1.3'
                            strokeMiterlimit={10}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 8.33571V1H1.66429'
                            stroke='currentColor'
                            strokeWidth='1.3'
                            strokeMiterlimit={10}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <img
                  className='md:hidden block w-full'
                  src='assets/phone-right.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-coolGray-50 px-4 md:px-0'>
          <div className='hidden-section container mx-auto px-4'>
            <h1 className='font-heading tracking-tighter text-5xl md:text-8xl lg:text-9xl md:mb-20 mb-12'>
              Our foundation
            </h1>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-3/12 xl:w-4/12 px-4 mb-16 lg:mb-0'>
                <div className='max-w-xs'>
                  <p className='text-lg text-coolGray-600 mb-10'>
                    The Oculoplastics Academy was created to promote the
                    training, research and patient care in
                    areas of aesthetic, plastic and reconstructive surgery
                    eyelids, lacrimal system, orbit and face. Check these out
                    content about us.
                  </p>
                  <a
                    className='group inline-flex items-center leading-none font-medium pb-2 border-b-2 border-black'
                    href='#'
                  >
                    <span className='mr-4'>Blog & more content</span>
                    <span className='group-hover:rotate-45 transform transition duration-100'>
                      <svg
                        width={11}
                        height={11}
                        viewBox='0 0 11 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.5 1.5L1.5 9.5'
                          stroke='black'
                          strokeWidth='1.3'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9.5 8.83571V1.5H2.16429'
                          stroke='black'
                          strokeWidth='1.3'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-9/12 xl:w-8/12 px-4'>
                <div className='flex flex-wrap -mx-4'>
                  <div className='w-full sm:w-1/2 px-4 mb-10 sm:mb-0'>
                    <a className='group block' href='#'>
                      <div className='relative mb-6 rounded-4xl overflow-hidden'>
                        <img
                          className='block w-full h-72 object-cover'
                          src='assets/about.jpeg'
                          alt=''
                        />
                        <div className='absolute left-0 top-0 h-full w-full group-hover:bg-opacity-10 group-hover:bg-black transition duration-200' />
                      </div>
                      <div className='max-w-lg'>
                        <h4 className='max-w-xs md:max-w-md text-3xl sm:text-4xl tracking-tight mb-6'>
                          Creation of the Oculoplastics Academy
                        </h4>
                        <p className='text-lg'>
                          Discover a new era in the concept of aesthetics and
                          oculofacial well-being, directly linked to
                          self-care and self-esteem.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className='w-full sm:w-1/2 px-4'>
                    <a className='group block' href='#'>
                      <div className='relative mb-6 rounded-4xl overflow-hidden'>
                        <img
                          className='block w-full h-72 object-cover'
                          src='assets/values.png'
                          alt=''
                        />
                        <div className='absolute left-0 top-0 h-full w-full group-hover:bg-opacity-10 group-hover:bg-black transition duration-200' />
                      </div>
                      <div className='max-w-lg'>
                        <h4 className='max-w-xs md:max-w-md text-3xl sm:text-4xl tracking-tight mb-6'>
                          Social responsibility and training
                        </h4>
                        <p className='text-lg'>
                          Learn about good environmental, social and
                          economic activities are an essential part of our
                          strategy.
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="py-12 md:py-20 bg-coolGray-50 px-4">
          <div class="hidden-section container mx-auto px-4">

            <div class="pt-8 md:pt-10 px-8 sm:px-12 xl:px-32 bg-black rounded-4xl">
              <div class="flex flex-wrap -mx-4 items-center">
                <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                  <div class="max-w-lg mx-auto">
                    <h3 class="text-white mt-8 md:mt-0 text-5xl lg:text-7xl tracking-tight mb-6">Recent events gallery</h3>
                    <p class="max-w-sm text-lg text-coolGray-500 mb-10">See what our 2022 and 2023 events looked like for the Oculoplastics community</p>
                    <a class="group inline-flex py-5 px-8 items-center justify-center leading-none font-medium text-white hover:text-black border border-white rounded-full hover:bg-white transition duration-200" href="https://gallery.oculoplastics.medfy.net">
                      <span class="mr-2">Visit the gallery</span>
                      <span class="group-hover:rotate-45 transform transition duration-100">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 1L1 9" stroke="currentColor" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M9 8.33571V1H1.66429" stroke="currentColor" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 px-4">
                  <img class="block" src="assets/gallery.png" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-coolGray-50 px-4 md:px-0'>
          <div className='hidden-section container mx-auto px-4'>
            <div className='flex flex-wrap -mx-4 items-center'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-lg mx-auto lg:mx-0'>
                  <h2 className='font-heading text-6xl sm:text-7xl tracking-tighter mb-6'>
                    International community
                  </h2>
                  <p className='max-w-md text-coolGray-600 mb-10'>
                    Meet our ambassadors and specialist in Oculoplastics
                    certified by Oculoplastics in your country on our platform.
                  </p>
                </div>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div className='max-w-xl lg:max-w-none mx-auto'>
                  <img
                    className='block w-full h-88 rounded-4xl object-cover'
                    src='assets/countries.svg'
                    alt=''
                  />
                </div>
              </div> 
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-black px-4 md:px-0'>
          <div className='hidden-section container mx-auto px-4'>
            <div className='max-w-lg mx-auto lg:max-w-none'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                  <div className='max-w-lg xl:max-w-none'>
                    <span className='block mb-4 text-sm text-white'>
                      Platform and exclusive group
                    </span>
                    <h1 className='font-heading tracking-tighter text-white mb-6 text-5xl md:text-8xl lg:text-9xl'>
                      Leading global community of experts in
                      oculoplastics
                    </h1>
                    <p className='xl:text-lg text-coolGray-500'>
                      High quality medical education present in leading countries worldwide
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='flex flex-col sm:flex-row flex-wrap lg:justify-end'>
                    <a
                      className='group inline-flex mb-3 sm:mb-0 sm:mr-6 py-5 px-8 text-black hover:text-white leading-none items-center justify-center bg-white hover:bg-blue-500 rounded-full transition duration-150'
                      href='https://oculoplastics.medfy.net'
                      target='_blank'
                    >
                      <span className='mr-2.5'>Sign up</span>
                      <span className='group-hover:rotate-45 transform transition duration-75'>
                        <svg
                          width={10}
                          height={11}
                          viewBox='0 0 10 11'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9 1.5L1 9.5'
                            stroke='currentColor'
                            strokeWidth='1.3'
                            strokeMiterlimit={10}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 8.83571V1.5H1.66429'
                            stroke='currentColor'
                            strokeWidth='1.3'
                            strokeMiterlimit={10}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='hidden-section py-12 md:py-20 px-4 md:px-0'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -mx-4 mb-24'>
              <div className='w-full xl:w-4/12 px-4 mb-12 xl:mb-0'>
                <a className='inline-block' href='#'>
                  <img
                    className='block h-7'
                    src='images/logo.svg'
                    alt=''
                  />
                </a>
              </div>
              <div className='w-1/2 md:w-1/4 xl:w-2/12 px-4 mb-8 md:mb-0'>
                <h5 className='text-sm font-medium text-coolGray-500 mb-4'>
                  About
                </h5>
                <ul>
                  <li className='mb-2'></li>
                  <li className='mb-2'>
                    <a className='inline-block text-lg text-black' href='#'>
                      Blog
                    </a>
                  </li>
                  <li className='mb-2'>
                    <a className='inline-block text-lg text-black' href='#'>
                      Contact
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-lg text-black' href='#'>
                      <span className='mr-2'>Support</span>
                      <span className='inline-block px-2 py-1 text-xs bg-blue-500 text-white uppercase rounded-full'>
                        ON
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-1/2 md:w-1/4 xl:w-2/12 px-4 mb-8 md:mb-0'>
                <h5 className='text-sm font-medium text-coolGray-500 mb-4'>
                  Company
                </h5>
                <ul>
                  <li className='mb-2'>
                    <a className='inline-block text-lg text-black' href='#'>
                      About
                    </a>
                  </li>
                  <li className='mb-2'>
                    <a className='inline-block text-lg text-black' href='#'>
                      Terms of service
                    </a>
                  </li>
                  <li className='mb-2'>
                    <a className='inline-block text-lg text-black' href='#'>
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='flex flex-wrap -mx-4 items-center relative'>
              <div className='w-full xl:w-4/12 px-4 mb-8 xl:mb-0'>
                <div className='md:flex items-center justify-between'>
                  <div className='-mb-2'>
                    <a
                      className='inline-block text-sm mb-2 mr-12 text-coolGray-500 hover:text-coolGray-600'
                      href='#'
                    >
                      CNPJ
                    </a>
                    <a
                      className='inline-block text-sm mb-2 text-coolGray-500 hover:text-coolGray-600'
                      href='#'
                    >
                      Legal
                    </a>
                  </div>
                </div>
              </div>
              <div className='hidden md:block md:w-1/2 xl:w-4/12 px-4 xl:text-center mb-8 md:mb-0'>
                <span className='text-sm text-coolGray-500'>
                  © 2023 Oculoplastics Academy in partnership with Medfy
                </span>
              </div>
              <div className='w-full md:w-1/2 xl:w-4/12 px-4'>
                <div className='flex flex-col xs:flex-row -mb-3 xs:items-center md:justify-end'>
                  <a
                    className='inline-flex mb-3 mr-8 items-center text-black'
                    href='#'
                  >
                    <svg
                      width={17}
                      height={13}
                      viewBox='0 0 17 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    ></svg>
                  </a>
                  <a
                    className='inline-flex mb-3 mr-8 items-center text-black'
                    href='https://www.instagram.com/oculoplastics.academy/'
                  >
                    <svg
                      width={19}
                      height={19}
                      viewBox='0 0 19 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.0018 4.8916C7.73315 4.8916 5.89648 6.73027 5.89648 8.99694C5.89648 11.2656 7.73515 13.1023 10.0018 13.1023C12.2705 13.1023 14.1072 11.2636 14.1072 8.99694C14.1072 6.72827 12.2685 4.8916 10.0018 4.8916ZM10.0018 11.6616C8.52915 11.6616 7.33715 10.4689 7.33715 8.99694C7.33715 7.52494 8.52982 6.33227 10.0018 6.33227C11.4738 6.33227 12.6665 7.52494 12.6665 8.99694C12.6672 10.4689 11.4745 11.6616 10.0018 11.6616Z'
                        fill='currentColor'
                      />
                      <path
                        d='M13.2988 1.05026C11.8268 0.981591 8.18082 0.984924 6.70749 1.05026C5.41282 1.11092 4.27082 1.42359 3.35015 2.34426C1.81148 3.88292 2.00815 5.95626 2.00815 8.99692C2.00815 12.1089 1.83482 14.1343 3.35015 15.6496C4.89482 17.1936 6.99815 16.9916 10.0028 16.9916C13.0855 16.9916 14.1495 16.9936 15.2395 16.5716C16.7215 15.9963 17.8402 14.6716 17.9495 12.2923C18.0188 10.8196 18.0148 7.17426 17.9495 5.70092C17.8175 2.89226 16.3102 1.18892 13.2988 1.05026ZM15.6288 14.6316C14.6202 15.6403 13.2208 15.5503 9.98349 15.5503C6.65015 15.5503 5.31348 15.5996 4.33815 14.6216C3.21482 13.5036 3.41815 11.7083 3.41815 8.98626C3.41815 5.30292 3.04015 2.65026 6.73682 2.46092C7.58615 2.43092 7.83615 2.42092 9.97415 2.42092L10.0042 2.44092C13.5568 2.44092 16.3442 2.06892 16.5115 5.76492C16.5495 6.60826 16.5582 6.86159 16.5582 8.99626C16.5575 12.2909 16.6202 13.6356 15.6288 14.6316Z'
                        fill='currentColor'
                      />
                      <path
                        d='M14.2718 5.68869C14.8017 5.68869 15.2312 5.25918 15.2312 4.72935C15.2312 4.19953 14.8017 3.77002 14.2718 3.77002C13.742 3.77002 13.3125 4.19953 13.3125 4.72935C13.3125 5.25918 13.742 5.68869 14.2718 5.68869Z'
                        fill='currentColor'
                      />
                    </svg>
                    <span className='ml-3 text-sm'>Instagram</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

